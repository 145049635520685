.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.container {
	width: 1350px;
}
.container > *:not(img,a){
	margin-top: 100px;
}
@media only screen and (max-width: 1600px) {
	.container {
		width: 1150px;
	}
}

@media only screen and (max-width: 1300px) {
	.container {
		width: 85vw;
	}
}
