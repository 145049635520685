.wrapper {
	position: relative;
	display: flex;
	box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
	border-radius: 20px;
	margin-bottom: 30px;
	height: 450px;
}
.info > h2 {
	font-size: 2rem;
	margin-bottom: 10px;
}

.info p {
	font-size: var(--text-fsize);
}

.wrapper a {
	color: white;
	text-decoration: none;
}

.wrapper img {
	height: 450px;
	border-radius: 20px;
}
.details {
	display: flex;
	flex-direction: column;
	padding: 35px 45px 35px 45px;
	border-radius: 10px;
	width: -moz-available;
}
.details > button > br {
	display: none;
}
.details > button {
	height: 50px;
	width: 400px;
	border: none;
	background-color: #94c507;
	color: white;
	font-weight: 800;
	border-radius: 15px;
	font-size: 1rem;
	margin-top: auto;

	transition: border 200ms ease-in-out;
}
.info > div {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
}
.info p {
	width: 50%;
}
.info > div:last-child {
	margin-top: 30px;
}

.info > div:last-child > p:last-child {
	color: #94c507;
}

.pic {
	display: flex;
	justify-content: space-between;
}
.pic img {
	height: 400px;
	border-radius: 20px;
}
.details > button:hover {
	cursor: pointer;
	border: 2px solid #4f4f4f;
}
.area::after {
	content: "2";
	font-size: 14px;
	position: relative;
	bottom: 7px;
}

@media only screen and (max-width: 1250px) {
	.wrapper,
	.wrapper img,
	main img {
		height: 350px;
	}
	.details {
		padding: 20px 30px 20px 30px;
	}
	.info > div:last-child {
		margin-top: 10px;
	}
	.details > button {
		height: 50px;
		width: 300px;
		font-size: 0.8rem;
	}
	.details p {
		font-size: 16px;
	}
	.info > h2 {
		font-size: 1.5rem;
	}
	.pic img {
		height: 300px;
	}
}
@media only screen and (max-width: 950px) {
	.wrapper {
		flex-direction: column;
		height: fit-content;
	}
	.wrapper img,
	.pic img {
		height: unset;
		width: 100%;
	}
	.details {
		flex-direction: column;
	}
	.info > div:last-child {
		margin-top: 30px;
	}

	.details > button {
		margin-top: 30px;
	}
	.details p {
		font-size: var(--text-fsize);
	}
	.info > h2 {
		font-size: 2rem;
	}
	.details > button {
		font-size: 0.9rem;
		height: 50px;
		width: 250px;
		text-align: left;
		padding-left: 20px;
	}
	.details > button > br {
		display: block;
	}
	.details p {
		width: auto;
	}
	.info > div {
		justify-content: unset;
		margin-top: 10px;
	}
	.info > div > p:last-child {
		margin-left: 8px;
	}
	.pic {
		flex-direction: column;
		gap: 30px;
	}
}
