footer {
	height: 130px;
	background-color: #111700;
	margin-top: 50px;
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

footer img {
	height: 90px;
}

footer a {
	display: flex;
	align-items: center;
	text-decoration: none;
}
footer a > img {
	height: 60px;
	margin-right: 10px;
}
footer a > p {
	font-size: 16px;
	color: white;
	font-weight: 800;
}

@media only screen and (max-width: 550px) {
	footer {
		height: auto;
		padding: 20px 0px;
	}
	.wrapper {
		height: auto;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
	}

	.wrapper > img {
		margin-bottom: 20px;
	}
}
