.contact {
	color: #4f4f4f;
}

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: var(--text-fsize);
}
.wrapper > p {
	line-height: 28px;
}

.wrapper > p > span {
	word-break: break-all;
}

.contactItem:first-child {
	margin-bottom: 16px;
}
.contactItem {
	display: flex;
	align-items: center;
	font-size: inherit;
	font-weight: 800;
}
.contactItem img {
	height: 40px;
}
.contactItem p {
	margin-left: 25px;
	word-break: break-all;
}

.contactItem > div {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(148, 197, 7, 0.15);
	min-width: 80px;
	min-height: 80px;
}

@media only screen and (max-width: 1150px) {
	.wrapper > p {
		width: 425px;
	}
}

@media only screen and (max-width: 1150px) {
	.wrapper {
		align-items: flex-start;
		justify-self: flex-start;
		flex-direction: column;
	}

	.wrapper > p {
		width: 100%;
		margin-top: 30px;
	}
}
