@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
	--title-fsize: 36px;
	--text-fsize: 20px;

	--header-img: url("./assets/hederBackgroud.jpeg");
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
}

body {
	overflow-x: hidden;
}

.title {
	text-decoration: underline;
	margin-bottom: 30px;
	font-size: var(--title-fsize);
	font-weight: 800;
}
