.about {
	margin-top: 50px;
	color: #4f4f4f;
	margin-bottom: 50px;
}

.about > p {
	font-size: var(--text-fsize);
	line-height: 28px;
	text-indent: 30px;
	font-weight: 600;
	text-align: justify;
	width: 100%;
}

.about b {
	font-weight: 900;
}
