.wrapper {
	background-image: var(--header-img);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	overflow-x: hidden;
	height: 100vh;
	width: 100vw;
	position: relative;
}

.opBackground {
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0.8) 25%,
		rgba(0, 0, 0, 0.1) 100%
	);
	display: flex;
	align-items: center;
	justify-content: flex-end !important;
	flex-direction: column;
}

.logo {
	width: 450px;
}

.content {
	margin-bottom: 150px;
	margin-top: auto;
}

.content > button {
	background-color: #94c507;
	color: white;
	text-transform: uppercase;
	border: none;
	border-radius: 15px;
	font-size: 16px;
	font-weight: 800;
	margin-top: 30px;
	width: 270px;
	height: 50px;
	transition: border 200ms ease-in-out;
}

.content > h1 {
	text-transform: uppercase;

	color: white;
	font-size: 32px;
	font-weight: 800;
	margin-top: 60px;
	min-width: 360px;
}

.separator {
	width: 105vw;
	position: absolute;
	bottom: 0;
}

.content button:hover {
	cursor: pointer;
	border: 2px solid white;
}

@media only screen and (max-width: 700px) {
	.opBackground {
		background: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0.8),
			rgba(0, 0, 0, 0.4)
		);
	}

	.logo {
		width: 350px;
	}

	.content h1 {
		margin-top: 30px;
	}
}

@media only screen and (max-width: 450px) {
	.logo {
		width: 250px;
	}

	.content h1 {
		font-size: 26px;
	}
}
